import React from "react";
import { Container, Divider, Grid, Image } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import HeadingOverImage from "../../components/heading-over-image/heading-over-image";
import FeatureList from "../../components/feature-list/feature-list";
import colors from '../../components/colors';
import Quote from "../../components/quote/quote";
import BodyContent from "../../components/body-content/body-content";

import style from "./wellbeing-assembly.module.css";

const Page = () => {
  return (
    <LayoutInner microsite>
      <SEO title="Free wellbeing boosting assembly" keywords={[`rocksteady`, `free`, `assembly`, `wellbeing`]} />

      <Container>
        <SecondaryNavBar
          product="primary"
          title="Free wellbeing boosting assembly"
          titlePath="/wellbeing-assembly/"
          titleActive={true}
          links={[]}
          cta="Yes, please send me the assembly"
          ctaMobile="Request assembly"
          ctaLink="https://form.jotform.com/RocksteadyMusic/freeresources"
        />

        <HeadingOverImage
          text="Free wellbeing boosting assembly for your school"
          img="/images/wellbeing-assembly/bg_1.jpg"
          siteSection="primary"
        />

        <Divider hidden />

        <BodyContent>
          <p>
            We have created a wellbeing boosting Music Assembly for use in UK primary schools.
            It’s lots of fun and a fantastic way to make everyone in your school feel great
            and it's completely free!​
          </p>
        </BodyContent>

        <FeatureList
          siteSection="primary"
          features={[
            {
              title: "An uplifting 30 minute video assembly",
              text:
                "Suitable for use in the classroom or any other space."
            },
            {
              title: "Suitable for all ages",
              text:
                "The assembly is suitable for KS1 and KS2."
            },
            {
              title: "No prep, planning or instruments needed",
              text:
                "It's easy for everyone to join in at the click of a button and have fun together."
            },
            {
              title: "Great for a class treat",
              text:
                "It's a fantastic way to start the morning or afternoon or as an end of day treat."
            },
            {
              title: "Learn a new original song",
              text:
                "Your pupils can learn an upbeat Rocksteady original song called ‘Bounce Back’ with on screen lyrics and a positive message about resilience."
            },
            {
              title: "Additional free 'Bounce Back' resources available",
              text:
                "After your class has enjoyed the Wellbeing assembly, why not check out the other 'Bounce Back' activities which are also free!​"
            }
          ]}
        />

        <BodyContent>
          <p className={style.ctaParagraph}>
            To get a copy of the Wellbeing Assembly for your school, simply click the link below​
          </p>
          <Grid verticalAlign="middle" stackable>
            <Grid.Row columns={2}>
              <Grid.Column textAlign="center">
                <a
                  href="https://form.jotform.com/RocksteadyMusic/freeresources"
                  className="ui button"
                  style={{
                    backgroundColor: colors["primary"].cta,
                    color: colors["primary"].ctaText,
                    borderRadius: 30,
                    fontSize: "2em",
                    lineHeight: "initial",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to request your<br />
                  FREE Wellbeing Video<br />
                  Assembly
                </a>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Image
                  size="large"
                  src="/images/wellbeing-assembly/wellbeing_video.jpg"
                  centered
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />
          <Grid columns={1}>
            <Grid.Column>
              <Quote
                siteSection="primary"
                quote="I have been impressed and delighted by how Rocksteady has been able to support our children with vital social and wellbeing activities​"
                quoteName="Stuart Allen Head, Mile Cross Primary School, Norwich​"
                reducedMargin
              />
            </Grid.Column>
          </Grid>
        </BodyContent>
      </Container>
    </LayoutInner>
  );
}

export default Page;
